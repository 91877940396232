.top-bar-switch {
  height: 1.5rem;
  .custom-switch {
    padding-left: 3rem;
    .custom-control-label {
      &:before {
        left: -3rem;
        height: 1.5rem;
        width: 3rem;
        border-radius: 0.75rem;
        background-color: $primary50-active;
      } 
      &:after {
        top: calc(0.25rem + 2px);
        left: calc(-3rem + 2px);
        width: calc(1.5rem - 4px);
        height: calc(1.5rem - 4px);
        background-color: $primary50-active;
        border-radius: 0.75rem;
      }
      
    }
    .custom-control-label {
      &:after {
        background-color: $neutral00;
        &:focus {
          outline: auto;
        }
      }
    }   
    .custom-control-input:checked ~ .custom-control-label {
      &:before {
        background-color:$neutral00;
      } 
      &:after {
        background-color: $primary60-hover;
        transform: translateX(1.5rem);
      }
    }     
  }
  .input-group-prepend, .input-group-append {
    span {
      background-color: transparent;
      border: none;
      color: $primary50-active;
    }
    .secondary-theme {
      color: $neutral00;
    }
  }
  .pre-label{
    color: $primary50-active;
    padding: 0.375rem 0.75rem;
    &.secondary-theme {
      color: $neutral00;
    }
  }
}

