.dropdown-select {
  margin-top: 10px;


  .keyboard-down-arrow-icon {
    float: right;
    margin-right: 10px;
    margin-top: -35px;
    position: relative;
    z-index: 5;
    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-select-toggle {
    width: 100%;
    box-sizing: border-box;
    box-shadow: inset 0px 1px 4px rgba(19, 20, 21, 0.2) !important;
    border-radius: 24px;
    background-color: white !important;
    border: 1px solid $primary50-active;
    box-sizing: border-box;
    font-weight: 400;
    color: $neutral80 !important;
    font-family: inherit;
    height: 38px;
    text-align: left;
    margin-bottom: 0;
    &:focus {
      box-shadow: inset 0px 1px 4px rgba(19, 20, 21, 0.2) !important;
      border: 1px solid $primary50-active;
    }

    &:after {
      display: none;
    }
    &.error {
      background: $error-background !important;
      border: 1px solid $error;
    }
  }

  .btn {
    filter: none !important;
    padding-left: 17px;
  }

  .dropdown-select-menu {
    min-width: 110%;
    background: white;
    padding: 12px 0px;
    box-shadow: 0px 1px 6px rgba(19, 20, 21, 0.2);
    border-radius: 16px;
    transform: translate(0px, 55px) !important;
  }

  .dropdown-item {
    height: 40px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 0;

    &:hover {
      color: black;
      background-color: $primary00-background;
    }
  }
  button[disabled] {
    background-color: $neutral10-background !important;
    border: 1px solid $neutral50 !important;
    color: $neutral50 !important;
  }
}
