.small-button {
  height: 1.5rem;
  font-family: $text-font-family;
  color: $primary50-active;
  background-color: #ffffff;
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 1;
  text-decoration: none !important;
  border-radius: 24px !important;
  padding: 0.25rem 0.75rem;
  box-shadow: 0px 1px 2px rgba(19, 20, 21, 0.18);
  border: none;
  filter: drop-shadow(0px 1px 2px rgba(19, 20, 21, 0.18));
  &:focus {
    outline: auto;
  }
  &:hover {
    text-decoration: none;
    color: $primary50-active;
    background-color: #ffffff;
  }
  &:focus {
    color: $primary50-active;
    background-color: #ffffff;
    outline: none;
    border: none;
    box-shadow: none;
  }
}
