.search-bar-row {
  height: 3.5rem;
}

#save-search-filters-tooltip {
  .tooltip-inner {
    background-color: $primary50-active;
    border: 1px solid $primary50-active;
  }

  .tooltip-arrow:before {
    border-bottom-color: $primary50-active;
  }
}

#save-search-filters-updated-label {
  font-size: .875rem;
  display: inline;
  font-style: normal;
  font-weight: 300 !important;
  color: $primary50-active;
  line-height: 1;
}
