.hero-image { 
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
  max-height: 240px;
  @include media-breakpoint-up(sm) {
    float: right;
  }
}

.hero-image-lg { 
  clip-path: polygon(20% 0, 100% 0, 80% 100%, 0% 100%);
}
.lead-padding {
  padding-top: 6rem;
}


.max-lead-width {
  max-width: $max-grid-width / 2;
}

.hero-container-position-lg {
  @media screen and (min-width: $max-grid-width) {
    // for some reason cannot use $max-grid-width in this mq
    padding-left: calc((100% - 1154px) / 2);
  }
}

.hero-date {
  font-family: $header-font-family;
  color: $primary20-outline;
  font-weight: 600;
  line-height: 1rem;
}
