.multiselect-dropdown-toggle {
  width: 100%;
  box-sizing: border-box;
  box-shadow: inset 0 1px 4px rgba(19, 20, 21, 0.2) !important;
  border-radius: 24px;
  background-color: white !important;
  border: 1px solid $primary50-active;
  font-weight: 400;
  color: $neutral80 !important;
  font-family: inherit;
  height: 38px;
  text-align: left;
  display: flex;
  padding-right: 10px;
  &:focus {
    box-shadow: inset 0 1px 4px rgba(19, 20, 21, 0.2) !important;
    border: 1px solid $primary50-active;
  }

  &:after {
    display: none;
  }
}

.multiselect-dropdown-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  flex: 1;
}

.multiselect-dropdown-menu {
  min-width: 110%;
  background: white;
  padding: 12px 0px;
  box-shadow: 0px 1px 6px rgba(19, 20, 21, 0.2);
  border-radius: 16px;
  transform: translate(0px, 55px) !important;
}

.keyboard-down-arrow {
  margin-top: auto;
  margin-bottom: auto;
}


.form-check-input:checked {
  background-color: $primary50-active;
  border-color: $primary50-active;
}

.form-check-dropdown {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  padding-left: 1.5rem;
  &:hover {
    color: black;
    background-color: $primary00-background;
  }
}

.form-check-label {
  margin-left: 10px;
  flex: 1;
  margin-bottom: 0;

  &:hover {
    cursor: pointer;
  }
}
