.extra-study-row {
  border-top: solid 1px $neutral30;

  .extra-study-label {
    font-weight: 300;
    color: $neutral70;
  }

  .icon-base::before {
    vertical-align: -1px;
  }

  .btn-link {
    background: none;
    filter: none;
    font-weight: 300;
    outline: none;

    p {
      color: $neutral70;
      text-decoration: underline;
    }

    &:focus {
      outline: 0;
    }
  }
}
