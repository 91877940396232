.accordion .icon {
  color: $secondary50;
  vertical-align: top;
  font-size: 3rem;
  margin: -0.75rem 0;
}
.accordion > .card {
  // first time ever I had to use !important :(
  border-radius: 16px !important;
}
.accordion h2 {
  cursor: pointer;
}