.card {
  border: none;
  border-radius: 16px;
  .card-title {
    .icon {
      color: $secondary50;
      vertical-align: top;
    }
  }
}
