p {
  font-family: $text-font-family;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0.5rem;
  color: $neutral90;
  &.blue {
    color: $primary50-active;
  }
  &.bold {
    font-weight: 600;
  }
  &.error {
    margin-bottom: 0;
  }
}
