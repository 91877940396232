.mobile-bar-button {
  position: absolute;
  z-index: 99;
  bottom: 0;
  height: 4rem;
  button {
    height: 4rem;
    border-radius: 0;
  }

  &.primary-theme {
    background-color: $neutral00;
  }

  &.secondary-theme {
    background-color: $primary50-active;
  }
}
