.search-table-col-label {
  .text {
    font-weight: 500;
    color: $primary50-active;
  }
  .icon {
    position: relative;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    &:before {
      position: absolute;
      top: 0rem;
      left: 0.25rem;
      width: 1rem;
      vertical-align: initial;
    }
    &:hover {
      background: $primary00-background;
    }
  }
}
