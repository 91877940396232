$header-font-family: 'Exo', sans-serif;
$text-font-family: 'Ubuntu', sans-serif;

// Colors
// Primary brand
$primary60-hover: #153451;
$primary50-active: #1f4e7b;
$primary60-footer: #153451;
$primary20-outline: #80a7cc;
$primary00-background: #ebf3fa;
// Secondary brand
$secondary60: #bb6707;
$secondary50: #f7921e;
// Neutral
$neutral90: #131415;
$neutral80: #212529;
$neutral70: #61656b;
$neutral60: #898e94;
$neutral50: #b4b7bb;
$neutral30: #dfe0e2;
$neutral20-background: #f4f5f5;
$neutral10-background: #f9fafa;
$neutral00: #ffffff;
// System
$error: #F7521E;
$success: #3A8D31;
$warning: #F8CB36;
$error-background: #FFF8F5;
$success-background: #EBFAEE;

$study-warning-background: #fff3cd;

// Break points
$max-grid-width: 1154px;
// Below used to achieve some left / right padding and table scroll manipulation only for devices larger than $max-grid-width
$safe-desktop-breakpoint: 1200px;

$filter-shadow-default: drop-shadow(0px 1px 6px rgba(19, 20, 21, 0.2));

$header-height: 4rem;
