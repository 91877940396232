h3 {
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  color: $primary50-active;
  font-family: $header-font-family;
  margin-bottom: 1rem;
}

.display-3 {
  font-style: normal;
  font-weight: 800;
  font-size: 1.25rem;
  color: $primary50-active;
  font-family: $header-font-family;
  margin-bottom: 1rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
  }
}
