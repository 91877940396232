.combined-input-fields {
  margin-top: 0px;
  margin-bottom: 1.5rem;

  input {
    padding-left: 3rem !important;
    background: $neutral00;
    border: 1px solid $primary50-active;
    box-sizing: border-box;
    box-shadow: inset 0 1px 4px rgba(19, 20, 21, 0.2);
    height: 48px;

    .material-icons,
    .material-icons-outlined {
      color: $secondary50;
      vertical-align: top;
    }
  }

  input[disabled] {
    background-color: $neutral10-background;
    border: 1px solid $neutral50;
    color: $neutral50;
  }
  
  .input-left {
    border-top-left-radius: 24px !important;
    border-bottom-left-radius: 24px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    width: 65%;
  }
  .input-right {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 24px !important;
    border-bottom-right-radius: 24px !important;
    width: 35%;
  }

  input.error {
    background: $neutral20-background;
    border: 1px solid $error;
    font-size: 0.875rem;
  }

  .form-control:focus {
    outline: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .icon {
    margin-left: 1rem;
    margin-top: 0.6rem;
    position: absolute;
    z-index: 5;
  }
}

.instruction {
  color: $primary50-active;
  padding: 0;
  font-size: 14px;
  margin-top: -0.5rem;
}
