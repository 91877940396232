
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1536px,
);

$container-max-widths: (
  sm: 540px,
  md: 768px,
  lg: 1024px,
  xl: 1536px,
);

$grid-gutter-width: 48px;

$font-family-base: $text-font-family;
