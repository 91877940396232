.single-line-text-input-field {
  margin-bottom: 1.5rem;
  display: relative;

  input {
    padding-left: 17px !important;
    background: $neutral00;
    border: 1px solid $primary50-active;
    box-sizing: border-box;
    box-shadow: inset 0 1px 4px rgba(19, 20, 21, 0.2);
    border-radius: 24px !important;
    height: 36px;
    width: 100%;
    .material-icons,
    .material-icons-outlined {
      color: $secondary50;
      vertical-align: top;
    }
    &.large {
      height: 3rem;
      margin-top: 0;
    }
    &.with-icon {
      padding-left: 3rem !important;
    }
  }
  input[disabled] {
    background-color: $neutral10-background;
    border: 1px solid $neutral50;
    color: $neutral50 !important;
  }

  input[disabled]::placeholder {
    color: $neutral50;
  }

  input.error {
    background: $error-background !important;
    border: 1px solid $error;
  }

  .form-control:focus {
    outline: none;
    z-index: 3;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  .icon {
    left: 1rem;
    top: 0.6rem;
    position: absolute;
    z-index: 5;
  }
}

.instruction {
  color: $primary50-active;
  padding: 0;
  font-size: 14px;
  margin-top: -0.5rem;
}
