.text-area-field {
  margin-top: 10px;
  
  textarea {
    padding-left: 17px!important;
    background: $neutral00;
    border: 1px solid $primary50-active;
    box-sizing: border-box;
    box-shadow: inset 0 1px 4px rgba(19, 20, 21, 0.2);
    border-radius: 24px;
    width: 100%;
    .material-icons, .material-icons-outlined  {
      color: $secondary50;
      vertical-align: top;
    }
  }

  textarea.error {
    background: #FFF8F5;
    border: 1px solid #F7521E;
  }

  .form-control:focus {
    outline: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}