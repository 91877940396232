.scrollable {
  height: 50vh;
  overflow-y: auto;
  // For Google Chrome
  &::-webkit-scrollbar {
    border-radius: 0.25rem;
    width:  0.5rem;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background: $primary20-outline;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.25rem;
    background: $primary00-background;
  }
}
