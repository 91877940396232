.list-item-wrapper {
  background: $neutral00;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
}

.list-item-structure {
  display: flex;
  flex-direction: column;
  align-content: center;
  .button-text {
    color: $primary20-outline;
  }
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.list-item-image-container {
  img {
    width: 100%;
  }
}

.button-wrapper {
  align-self: flex-end;
}
