.light {
  color: $primary20-outline;
}

.regular {
  font-weight: 400;
  color: $primary50-active;
}

.password-indicator {
  align-items: flex-end;
  height: 22px;

  p {
    margin: 0;
    font-size: 14px;
  }
}

.password-indicator-icon {
  padding-bottom: 3px;
  margin-right: 0.5rem;
}
