.lead {
  font-family: $text-font-family;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 150%;
  color: $neutral90;
  margin-bottom: 1rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.125rem;
  }
}
