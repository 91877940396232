.study-table {
  background: transparent;
  margin: 0 1.5rem;

  .study-table-row {
    line-height: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-style: normal;
    font-weight: 300;
    &.study-header-row {
      background: $primary00-background;
      color: $primary50-active;
      font-weight: 400;
    }
    .study-table-col {
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      &.date-col,
      &.link-col {
        width: 8rem;
      }
      &.procedures-col {
        width: 7rem;
      }
      &.accession-col {
        width: 9rem;
      }
      &.description-col {
        width: 15rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.doctor-col {
        width: 12rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.lock-col {
        width: 3rem;
        line-height: 0;
        .lock-icon {
          &:before {
            vertical-align: revert !important;
          }
        }
      }
      .images-missing {
        color: $neutral70;
      }
    }
  }

  .study-table-row--unlocked {
    background-color: $study-warning-background;
  }
}
