.anchor-text {
  color: $primary50-active;
}

.password-toggle {
  float: right;
  margin-right: 10px;
  margin-top: -34px;
  position: relative;
  z-index: 5;
  &:hover {
    cursor: pointer;
  }
}
