.richtext {
  h1, h2, h3, p, figure {
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      max-width: 100%;
    }
  }
  img {
    width: 100%;
  }
  a {
    text-decoration: underline;
  }
  a:hover {
    color: $primary60-hover;
  }
}
