.checkbox-input {
  display: flex;
  align-items: center;
}

.checkbox-field {
  margin-bottom: 24px;

  input {
    border: 1px solid $primary50-active;
    box-sizing: border-box;
    border-radius: 4px;
  }

  input:hover {
    border: 1px solid $primary20-outline;
  }

  input:checked {
    background: $primary50-active;
  }

  input.error {
    background: $error-background ;
    border: 1px solid $error;
    font-size: 0.875rem;
  }

  label.form-check-label{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
  }
}
