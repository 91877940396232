.footer-secondary-theme {
  background-color: $primary60-footer;
  color: $neutral00;
  a {
    color: $neutral00;
  }
}

.footer-size {
  min-height: 10rem;
}

.footer-spacing {
  padding: 3rem;
}