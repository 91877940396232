
@import '../../styles/constants';

.embed-iframe {
  position: relative;
  width: 100%;
}

.images-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - 4rem);
}
