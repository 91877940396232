.icon-base {
  font-size: 1.5rem;
  line-height: 1.5rem;

  &:before {
    vertical-align: -6px;
  }
}

.icon-event {
  @extend .icon-base;

  &:before {
    content: url('./event.svg');
  }
}

.icon-article {
  @extend .icon-base;

  &:before {
    content: url('./article.svg');
  }
}

.icon-indicator {
  @extend .icon-base;

  &:before {
    content: url('./indicator.svg');
  }
}

.icon-indicator-invalid {
  @extend .icon-base;

  &:before {
    content: url('./indicator-invalid.svg');
  }
}

.icon-close {
  @extend .icon-base;

  &:before {
    content: url('./close.svg');
  }
}

.icon-error_outline {
  @extend .icon-base;

  &:before {
    content: url('./error_outline.svg');
  }
}

.icon-info {
  @extend .icon-base;

  &:before {
    content: url('./info.svg');
  }
}

.icon-keyboard_arrow_right {
  @extend .icon-base;

  &:before {
    content: url('./keyboard_arrow_right.svg');
  }
}

.icon-keyboard_arrow_down {
  @extend .icon-base;

  &:before {
    content: url('./keyboard_arrow_down.svg');
  }
}

.icon-keyboard_arrow_down-disabled {
  @extend .icon-base;

  &:before {
    content: url('./keyboard_arrow_down-disabled.svg');
  }
}

.icon-keyboard_arrow_up {
  @extend .icon-base;

  &:before {
    content: url('./keyboard_arrow_up.svg');
  }
}

.icon-location_on {
  @extend .icon-base;

  &:before {
    content: url('./location_on.svg');
  }
}

.icon-mail {
  @extend .icon-base;

  &:before {
    content: url('./mail.svg');
  }
}

.icon-menu {
  @extend .icon-base;

  &:before {
    content: url('./menu.svg');
  }
}

.icon-phone {
  @extend .icon-base;

  &:before {
    content: url('./phone.svg');
  }
}

.icon-portrait {
  @extend .icon-base;

  &:before {
    content: url('./portrait.svg');
  }
}

.icon-print {
  @extend .icon-base;

  &:before {
    content: url('./print.svg');
  }
}

.icon-search {
  @extend .icon-base;

  &:before {
    content: url('./search.svg');
  }
}

.icon-update-search {
  @extend .icon-base;

  &:before {
    content: url('./update-search.svg');
  }
}

.icon-documents-keyboard-arrow-down {
  @extend .icon-base;

  &:before {
    content: url('./documents-keyboard-arrow-down.svg');
    vertical-align: 0;
  }
}

.icon-documents-keyboard-arrow-up {
  @extend .icon-base;

  &:before {
    content: url('./documents-keyboard-arrow-up.svg');
    vertical-align: 0;
  }
}

.icon-today {
  @extend .icon-base;

  &:before {
    content: url('./today.svg');
  }
}

.icon-today-disabled {
  @extend .icon-base;

  &:before {
    content: url('./today-disabled.svg');
  }
}

.icon-person {
  @extend .icon-base;

  &:before {
    content: url('./person.svg');
  }
}

.icon-person-disabled {
  @extend .icon-base;

  &:before {
    content: url('./person-disabled.svg');
  }
}

.icon-place {
  @extend .icon-base;

  &:before {
    content: url('./location_on.svg');
  }
}

.icon-back {
  @extend .icon-base;

  &:before {
    content: url('./back.svg');
  }
}

.icon-back_blue {
  @extend .icon-base;

  &:before {
    content: url('./back_blue.svg');
  }
}

.icon-input-hide {
  @extend .icon-base;

  &:before {
    content: url('./input-hide.svg');
  }
}

.icon-input-show {
  @extend .icon-base;

  &:before {
    content: url('./input-show.svg');
  }
}

.icon-move-from {
  @extend .icon-base;

  &:before {
    content: url('./move-from.svg');
  }
}

.icon-lock {
  @extend .icon-base;

  &:before {
    content: url('./lock.svg');
  }
}

.icon-lock-open {
  @extend .icon-base;

  &:before {
    content: url('./lock-open.svg');
  }
}

.icon-sort {
  @extend .icon-base;

  &:before {
    content: url('./sort.svg');
  }
}

.icon-download {
  @extend .icon-base;

  &:before {
    content: url('./download.svg');
  }
}

.icon-success {
  @extend .icon-base;

  &:before {
    content: url('./success.svg');
  }
}

.icon-error {
  @extend .icon-base;

  &:before {
    content: url('./error.svg');
  }
}

.icon-primary {
  @extend .icon-base;
  &:before {
    content: url('./primary.svg');
  }
}

.icon-warning {
  @extend .icon-base;

  &:before {
    content: url('./warning.svg');
  }
}

.icon-image-search {
  @extend .icon-base;

  &:before {
    content: url('./image_search.svg');
  }
}

.icon-pdf {
  @extend .icon-base;

  &:before {
    content: url('./pdf.svg');
  }
}

.icon-check_circle {
  @extend .icon-base;

  &:before {
    content: url('./check_circle.svg');
  }
}
