a {
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0.5rem;
  color: $primary50-active;
  cursor: pointer;
  &:hover{
    color: $primary60-hover;
  }
}

.material-icons, .material-icons-outlined {
  color: $secondary50;
  vertical-align: top;
}