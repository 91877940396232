.search-table {
  border-radius: 1rem;
  border: 1px solid $neutral50;
  background: $neutral00;
  &.overflow-x-scroll-mobile {
    overflow-x: scroll;
    @media screen and (min-width: $safe-desktop-breakpoint) {
      overflow-x: hidden;
    }
  }
  .search-table-row-study-table-wrapper {
    max-width: $max-grid-width
  }

  .search-table-row {
    padding: 1rem;
    cursor: pointer;
    margin-left: -1rem;
    margin-right: -1rem;
    max-width: $max-grid-width;

    .search-table-col {
      line-height: 30px;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .lock-col {
      text-align: right;
      .lock-icon {
        padding-right: 1.4rem;
        &:before {
          vertical-align: revert !important;
        }
      }
    }
  }

  .search-table-header-row-wrapper {
    border-bottom: 1px solid $neutral50;
    min-width: $max-grid-width;
    &.active {
      background: $neutral10-background;
    }
    &:first-child {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
    &:last-child {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      border-bottom: none;
    }
  }

  .search-table-row-wrapper {
    border-bottom: 1px solid $neutral50;
    min-width: $max-grid-width;
    &.loading-row {
      min-width: calc(1154px - 100px);
    }
    &.active {
      background: $neutral10-background;
    }
    &:first-child {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
    &:last-child {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      border-bottom: none;
    }
    &:hover {
      background-color: $neutral10-background;
    }
  }
}
