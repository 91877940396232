.call-to-action-wrapper {
  width: auto;
  margin-left: 0;
  margin-top: 16px;
  margin-right: 1rem;
  padding-right: 0 !important;
  button {
    text-align: center;
  }
  @include media-breakpoint-up(lg) {
    button {
      text-align: left;
    }
  }
  .btn {
    margin-bottom: 0;
    span {
      &:before {
        vertical-align: -4px;
      }
    }
  }
}

.call-to-action-outer-wrapper {
  align-self: center;
  width: 100%;
  @include media-breakpoint-up(lg) {
    align-self: flex-end;
  }
}

.call-to-action-a-tag {
  color: inherit;
  text-decoration: none;
}

.actions-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
