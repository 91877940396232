h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 120%;
  color: $primary50-active;
  font-family: $header-font-family;
  margin-bottom: 1rem;
  @include media-breakpoint-up(lg) {
    font-size: 2rem;
  }
}

.display-1 {
  font-style: normal;
  font-weight: 800;
  font-size: 1.75rem;
  color: $primary50-active;
  font-family: $header-font-family;
  margin-bottom: 1rem;
  @include media-breakpoint-up(lg) {
    font-size: 2.75rem;
  }
}
