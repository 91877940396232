.nav {
  margin-left: auto;
  a {
    text-decoration: none;
  }
}

#basic-nav-dropdown {
  padding: 0;
}

.nav-dropdown {
  height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 0px;
}

.desktop-nav {
  .desktop-nav-line-height {
    line-height: 1rem;
  }
  .two-cols {
    .dropdown-menu {
      columns: 2;
    }
  }

  .nav-item {
    margin-right: 0.5rem;
    font-family: $header-font-family;
    font-weight: 600;
    line-height: 1;
    .dropdown-toggle {
      &:after {
        vertical-align: middle;
        margin-left: 0.5rem;
      }
    }

    .nav-link {
      height: 3rem;
      border-radius: 1.5rem;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      margin-bottom: 0;
      color: $neutral00;
      background-color: transparent;
      &.show {
        .nav-link {
          background-color: transparent;
          color: $primary50-active;
        }
      }
      &.active {
        background-color: transparent;
        color: white;
      }
      &:hover {
        color: $neutral00;
      }
    }
  }

  .dropdown.show {
    .dropdown-toggle {
      vertical-align: middle;
      background-color: $primary60-hover;
    }
  }

  .dropdown-menu {
    top: 0.5rem !important;
    border: none;
    filter: $filter-shadow-default;
    border-radius: 1rem;
    .dropdown-item {
      padding: 0.5rem 1rem;
      border-radius: 0;
      font-family: $text-font-family;
      color: $primary60-hover;
      font-weight: 300;
      margin-bottom: 0;
      &.active {
        background-color: $primary60-hover;
      }
      &:hover {
        background-color: $primary00-background;
      }
    }
  }
}
