.dropdown-formcontrol {
  border: none;
  box-sizing: border-box;
  height: 34px;
  padding: 0;
  background-color: transparent !important;
  &:focus, &:active {
    outline: none !important;
    box-shadow: none;
  }
}

.dropdownGroup {
  position: absolute;
  margin-top: 7px;
  padding-bottom: 8px;
  background-color: $neutral00;
  z-index: 1;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 1px 6px rgba(19, 20, 21, 0.2);
}

.dropdownItem:hover {
  background-color: $primary00-background;
  width: 100%;
}

.dropdown-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-group-prepend, .input-group-append {
  display: inline;
}

