.mobile-nav  {
  overflow-y: scroll;
  height: calc(100% - 12rem);
  flex-wrap: nowrap;
  .nav-item {
    font-family: $header-font-family;
    font-weight: 600;
    line-height: 1;
    &.nav-item-lvl-2 {
      font-family: $text-font-family;
      font-weight: 400;
    }
    .dropdown-toggle {
      &:after {
        margin-left: 0.75rem;
      }
    }
    .nav-link {
      height: 4rem;
      border-radius: 0;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
      border-bottom: 1px solid $primary00-background;
      margin-bottom: 0;
      &.active {
        background-color: $neutral00;
        color: $primary50-active;
      }
    }
  }
}

.secondary-theme.mobile-nav  {
  .nav-item {
    .nav-link {
      border-bottom: 1px solid $primary60-hover;
      color: $neutral00;
      &.active{
        background-color: $primary50-active;
        color: $neutral00;
      }
    }
  }
}
