.name-auto-complete {
  .input-group-prepend, .input-group-append {
    display: inline;
    margin-top: 3px;
    margin-left: 8px;
    margin-right: 8px;
  }
  
  .input {
    padding-left: 17px!important;
    background: $neutral00;
    border: 1px solid $primary50-active;
    box-sizing: border-box;
    box-shadow: inset 0 1px 4px rgba(19, 20, 21, 0.2);
    border-radius: 24px;
    height: 36px;
    width: 100%;
    .material-icons, .material-icons-outlined  {
      color: $secondary50;
      vertical-align: top;
    }
  }

  .input:disabled {
    background: #F9FAFA;
    border: 1px solid #B4B7BB;
    box-shadow: inset 0px 1px 4px rgba(19, 20, 21, 0.2);
  }

  .form-control:focus {
    outline: none;
  }

  .input-error {
    color: $error;
    border: 1px solid $error;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

