.modal-content {
  border-radius: 1rem;
}

.modal-body {
  padding: 0 2rem 1.5rem 2rem;
}

.modal-header {
  padding: 1.5rem 1.5rem 0;
  border: none;
}

.close {
  .sr-only {
    color: $primary50-active;
  }
  background-color: transparent;
  border: none;
}