.empty-state-no-results {
  color: $neutral70;
  .title {
    margin: auto auto 16px auto;
    font-size: 18px;
    height: 27px;
  }
  .sub-title {
    font-size: 14px;
    margin: auto auto 8px auto;
  }
  li {
    font-size: 14px;
  }
}
