// @ TODO  import only needed styles

@import "./constants";
@import "./_settings";
@import "node_modules/bootstrap/scss/bootstrap";
@import "./globals";

.text-large {
  font-size: 1.6rem;
}

@import "../components/atoms/H1/h1.scss";
@import "../components/atoms/H2/h2.scss";
@import "../components/atoms/h3/h3.scss";
@import "../components/atoms/p/p.scss";
@import "../components/atoms/a/a.scss";
@import "../components/atoms/button/button.scss";
@import "../components/atoms/Icon/Icon.scss";
@import "../components/atoms/Lead/lead.scss";
@import "../components/atoms/ErrorText/errorText.scss";
@import "../components/atoms/ButtonText/ButtonText.scss";
@import "../components/atoms/RichText/RichText.scss";
@import "../components/atoms/AddressText/AddressText.scss";
@import "../components/atoms/SwitchToggle/switchToggle.scss";
@import "../components/atoms/FormCheck/FormCheck.scss";
@import "../components/atoms/SearchTable/SearchTable.scss";
@import "../components/atoms/SearchTableColumnLabel/SearchTableColumnLabel.scss";
@import "../components/atoms/SearchTableHeaderRow/SearchTableHeaderRow.scss";
@import "../components/molecules/AlertBox/AlertBox.scss";
@import "../components/molecules/TextSection/textSection.scss";
@import "../components/molecules/AccordionTextSection/textSection.scss";
@import "../components/molecules/HeroSection/heroSection.scss";
@import "../components/molecules/ListItem/ListItem.scss";
@import "../components/molecules/CallToAction/CallToAction.scss";
@import "../components/molecules/Modal/Modal.scss";
@import "../components/molecules/MobileBarButton/mobileBarButton.scss";
@import "../components/organisms/Header/header.scss";
@import "../components/organisms/MobileNavigation/mobileNavigation.scss";
@import "../components/organisms/Navigation/desktopNavigation.scss";
@import "../components/organisms/Footer/footer.scss";
@import "../components/organisms/EmptyState/EmptyState.scss";
@import "../components/atoms/InputLabel/InputLabel.scss";
@import "../components/atoms/Label/Label.scss";
@import "../components/atoms/Input/Input.scss";
@import "../components/molecules/Select/index.scss";
@import "../components/forms/fields/SingleLineTextInputField/SingleLineTextInputField.scss";
@import "../components/forms/fields/CombinedInputFields/CombinedInputFields.scss";
@import "../components/forms/fields/TextareaField/TextareaField.scss";
@import "../components/forms/fields/FormSubmitButton/FormSubmitButton.scss";
@import "../components/forms/fields/NameAutoComplete/NameAutoComplete.scss";
@import "../components/forms/fields/DropdownSelect/DropdownSelect.scss";
@import "../components/forms/fields/RadioButtonGroup/RadioButtonGroup.scss";
@import "../components/forms/fields/MultiselectDropdown/MultiSelectDropdown.scss";
@import "../components/forms/fields/NpsScale/NpsScale.scss";
@import "../components/forms/fields/CheckboxField/CheckboxField.scss";
@import "../components/forms/validations/PasswordIndicator/PasswordIndicator.scss";
@import "../pages/LoginPage/LoginForm/LoginForm.scss";
@import "../pages/AccountPage/AccountPage.scss";
@import "../pages/PatientReportPage/PatientReportCard/PatientReportCard.scss";
@import "../pages/PatientSearchPage/StudyTable/StudyTable.scss";
@import "../pages/PatientImageSetsPage/PatientImageSetsPage.scss";
@import "../components/atoms/LoadingBox/LoadingBox.scss";
@import "../components/atoms/IndicatorInternalLink/IndicatorInternalLink.scss";
@import "../pages/PatientSearchPage/StudyTableColumnLabel/StudyTableColumnLabel.scss";
@import "../pages/PatientSearchPage/PatientSearchBar/PatientSearchBar.scss";
@import "../pages/RegisterAccount/RegisterAccountForm/FormSections/Finish.scss";
@import "../pages/RegisterAccount/Success/Success.scss";
@import "../components/atoms/SmallButton/SmallButton.scss";
@import "../pages/PatientSearchPage/AcceptTC/TCAcceptanceModal/TCAcceptanceModal.scss";
@import "../pages/PatientSearchPage/StudyTableExtraStudiesRow/StudyTableExtraStudiesRow.scss";
@import "../pages/AppointmentSearchPage/ProcedureTable/ProcedureTable.scss";
@import "../pages/AppointmentSearchPage/ProcedureTableColumnLabel/ProcedureTableColumnLabel.scss";
