.report {
  .pdf_body {
    font-family: $text-font-family !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 1.5em !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: $neutral90 !important;
  }

  table {
    margin-bottom: 2rem !important;
    div {
      margin-bottom: 0rem !important;
    }
  }

  div {
    margin-bottom: 1rem !important;
    line-height: 1.5em !important;
  }

  // Reduce extra white space in report - see https://dev.azure.com/au-palo-it/I-MED%20Online/_workitems/edit/772
  div._aw_p:empty {
    display: none;
  }

  ._aw_table_borderless {
    margin-bottom: 1rem !important;
  }
}
