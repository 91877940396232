.indicator {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  background: transparent;
  margin: 0.5rem;
  margin-left: 0;

  &.error {
    background: $error;
  }
  &.success {
    background: $success;
  }
  &.warning {
    background: $warning;
  }
}

.indicator-text {
  color: $neutral90;
}
