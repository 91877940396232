.radio-button-group {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  .error{
    input[type='radio'] {
      background: $error-background;
      border: 1px solid $error;
    }
  }
  input[type='radio'] {
    border: 1px solid $primary50-active;
    box-sizing: border-box;
    border-radius: 1rem;
    margin-right: 0;

    &:checked {
      background-color: $primary50-active;
      background-image: url('selected.svg');
    }
    &:hover {
      border: 1px solid $primary20-outline;
    }
  }

  .form-check-inline {
    padding-left: 0;
    &>input[type='radio'] {
      margin-left: 0;
    }
  }
}
