.AlertBox {
  font-family: $text-font-family;
  font-weight: 400;
  border-radius: 0.25rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.AlertBox-primary {
  color: $primary50-active;
  background-color: $primary00-background;
  border: solid 1px $primary20-outline;
}

.AlertBox-success {
  color: $success;
  background-color: $success-background;
  border: solid 1px $success;
}

.AlertBox-error {
  color: $error;
  background-color: $error-background;
  border: solid 1px $error;
}

.AlertBox-warning {
  color: $primary50-active;
  background-color: $primary00-background;
  border: solid 1px $primary20-outline;
}

.AlertBox-success {
  color: $success;
  background-color: $success-background;
  border: solid 1px $success;
}
