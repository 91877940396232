body {
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0em;
  text-align: left;
  color: $neutral90;
  b {
    font-weight: 500;
  }
}

// spacial gutter widths for mobile
@media (max-width: map-get($grid-breakpoints, md)){
  .row > .col,
  .row > [class*="col-"] {
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)){
  .row > .col,
  .row > [class*="col-"] {
    padding-right: 32px;
    padding-left: 32px;
  }
}

.content-background {
  background-color: $neutral20-background !important;
}

.content-unauthenticated-background-min-height {
  /* following calculation: 100 vh - top nav bar */
  min-height: calc(100vh - 4rem);
}


.content-background-min-height {
  /* following calculation: 100 vh - top nav bar - hero section - footer */
  min-height: calc(100vh - 4rem - 256px);
}

.max-grid-width {
  max-width: $max-grid-width;
}

.content-padding {
  // for some reason cannot use $max-grid-width in this mq
  @media screen and (min-width: $safe-desktop-breakpoint) {
    padding-left: 0;
    padding-right: 0;
  }
}


.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.embed-responsive-bookimed-ratio {
  &:before {
    padding-top: 300%;
  }
  @include media-breakpoint-up(lg) {
    &:before {
      padding-top: 56.25%
    }
  }
}
.embed-responsive-form-large-ratio {
  &:before {
    padding-top: 600%;
  }
  @include media-breakpoint-up(sm) {
    &:before {
      padding-top: 300%
    }
  }
  @include media-breakpoint-up(md) {
    &:before {
      padding-top: 160%
    }
  }
  @include media-breakpoint-up(lg) {
    &:before {
      padding-top: 130%
    }
  }
}
.embed-responsive-form-small-ratio {
  &:before {
    padding-top: 250%;
  }
  @include media-breakpoint-up(sm) {
    &:before {
      padding-top: 200%
    }
  }
  @include media-breakpoint-up(md) {
    &:before {
      padding-top: 100%
    }
  }
  @include media-breakpoint-up(lg) {
    &:before {
      padding-top: 90%
    }
  }
}


.h-90 {
  height: 90%;
}

.py-120 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include media-breakpoint-up(lg) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.pt-140 {
  padding-top: 2rem;
  @include media-breakpoint-up(lg) {
    padding-top: 100px;
  }
}
.pb-140 {
  padding-top: 2rem;
  @include media-breakpoint-up(lg) {
    padding-top: 100px;
  }
}

.pb-100 {
  padding-bottom: 2rem;
  @include media-breakpoint-up(lg) {
    padding-bottom: 40px;
  }
}

.py-100 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include media-breakpoint-up(lg) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.va-bottom {
  vertical-align: bottom;
}

.min-vh-75 {
  min-height: 75vh;
}

.t-separator {
  border-top: 1px solid $neutral30;
  padding-top: 1rem;
}

.c-pointer {
  cursor: pointer;
}

.card-title {
  color: $primary50-active;
}

.card-body {
  padding: 1.5rem;
}

.new-line {
  white-space: pre-line;
}
