.btn {
  height: 3rem;
  font-family: $header-font-family;
  color: $primary50-active;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  border-radius: 24px;
  padding: 0 1.5rem;
  margin-bottom: 1rem;
  border: none;
  filter: drop-shadow(0px 1px 2px rgba(19, 20, 21, 0.18));
  .material-icons,
  .material-icons-outlined {
    color: $secondary50;
    vertical-align: top;
  }
  &:focus {
    outline: auto;
  }
}

.btn-fixed {
  width: 288px;
  text-align: left;
}
.btn-primary {
  background: $primary50-active;
  color: $neutral00;
  &:hover {
    background: $primary60-hover;
  }
  &:focus {
    box-shadow: none;
    border: none;
    background: $primary50-active;
  }
}

.btn-primary[disabled] {
  background-color: $neutral50;
}

.btn-light {
  color: $primary50-active;
  background: $neutral00;
  &:hover {
    color: $primary50-active;
    background: $primary00-background;
  }
  &:focus {
    color: $primary50-active;
    background: $neutral00;
  }
}

.btn-link {
  color: $primary50-active;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-align: left;
  background-color: #ffffff;
  height: auto;
  text-decoration: none;

  &.no-outline {
    filter: none;
  }

  &:hover {
    text-decoration: none;
    color: $primary50-active;
  }
}

.btn-secondary {
  color: $primary50-active;
  font-weight: 100;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-align: left;
  background-color: #ffffff;
  height: auto;
  text-decoration: underline;
  filter: none;
  &:hover {
    text-decoration: none;
    color: $primary50-active;
    background-color: #ffffff;
  }
  &:focus {
    color: $primary50-active;
    background-color: #ffffff;
    outline: none;
    border: none;
    box-shadow: none;
  }
}
