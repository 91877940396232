.procedure-table {
  background: transparent;

  .procedure-table-row {
    line-height: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-style: normal;
    font-weight: 300;

    &.procedure-header-row {
      background: $primary00-background;
      color: $primary50-active;
      font-weight: 400;
    }

    .procedure-table-col {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}