.form-check {
  padding-left: 0;
  .form-check-input {
    margin: 0 auto !important;
    float: none;
    cursor: pointer;
    &:checked {
      background-color: $primary50-active;
      border-color: $primary50-active;
    }
  }

  .form-check-dropdown {
    padding-left: 1rem;
    .form-check-label {
      margin-top: 2px;
    }
  }
}
