.study-table-col-label-icon {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  &:before {
    position: absolute;
    top: 0rem;
    left: 0.25rem;
    width: 1rem;
    vertical-align: initial;
  }
  &:hover {
    background: $neutral00;
  }
}
