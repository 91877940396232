.table-header-row-wrapper {
  border-bottom: 1px solid $neutral50;
  min-width: $max-grid-width;

  &.active {
    background: $neutral10-background;
  }

  &:first-child {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  &:last-child {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-bottom: none;
  }
}

.search-table-row-wrapper {
  border-bottom: 1px solid $neutral50;
  min-width: $max-grid-width;

  &.loading-row {
    min-width: calc(1154px - 100px);
  }

  &.active {
    background: $neutral10-background;
  }

  &:first-child {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  &:last-child {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-bottom: none;
  }

  &:hover {
    background-color: $neutral10-background;
  }
}
