.top-bar {
  box-shadow: 0px 2px 16px rgba(19, 20, 21, 0.08);
  height: 4rem;
  position: relative;
  &.primary-theme {
    background-color: $neutral00;
  }

  &.secondary-theme {
    background-color: $primary50-active;
  }

  .max-top-bar-width {
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: 1536px;
    }
  }
  .menu-icon {
    color: $secondary50;
    vertical-align: top;
    font-size: 2.5rem;
    margin: 0 1rem 0.5rem;
  }
  .menu-icon-label {
    position: absolute;
    top: 2.5rem;
    left: 1.1rem;
    font-size: 0.9rem;
    line-height: 1rem;
    color: $primary50-active;
    &.active {
      color: $neutral00;
    }
  }
  .placeholder-icon {
    width: 2.5rem;
    height: 2.5rem;
    margin: 1.5rem 1rem;
  }
}

.mobile-menu {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $neutral00;
  height: 100vh;
  &.active {
    background-color: $primary50-active;
  }
}

.absolute-nav {
  z-index: 99;
  position: absolute;
  top: 4rem;
  margin: 1.5rem auto;
  left: 0;
  .grid-width {
    width: $max-grid-width;
  }
}

.mr-2 {
  margin-right: 5px;
}

.btn-menu {
  &.logout {
    width: 140px;
  }
  &.back {
    width: 200px;
  }
}
