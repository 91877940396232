.nps-scale {
  margin-top: 10px;
  
  .page-link {
    color : #1F4E7B;
  }
  .page-item.active .page-link{
    background-color: #1F4E7B;
    border-color: #3F6E9B;  
  }
}