.loading-box {
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  min-height: 1rem;
  min-width: 1rem;
  background: $neutral30;
  position: relative;
  overflow: hidden;
  &.narrow {
    width: 80%;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, $neutral20-background 50%, transparent 100%);
    animation: load 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  @keyframes load {
    from {
      left: -150px;
    }
    to {
      left: 100%;
    }
  }
}
